var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.events,"headers":[
      { text: 'Wettkampftag', value: 'matchday', sortable: false },
      { text: 'Beginn', value: 'startDate', sortable: false },
      { text: 'Liga', value: 'liga.shortName', sortable: false },
      !_vm.overview ? { text: 'Name', value: 'name', sortable: false } : undefined,
      { text: 'Mannschaften', value: 'mannschaften', sortable: false, align: 'center' },
      _vm.team ? { text: 'Neutralverzicht', value: 'neutralwaiver', sortable: false, align: 'center' } : undefined,
      { text: 'Punkte', value: 'punkte', sortable: false, align: 'center' },
      !_vm.overview ? { text: 'GP', value: 'gp', sortable: false, align: 'center' } : undefined,
      !_vm.overview ? { text: 'TP', value: 'tp', sortable: false, align: 'center' } : undefined,
      _vm.admin ? { text: 'Status', value: 'status', sortable: false, align: 'center' } : undefined,
      _vm.admin ? { text: 'Werten', value: 'werten', sortable: false, align: 'center' } : undefined,
      !_vm.overview ? { text: 'Hinweise', value: 'publicComment', sortable: false, align: 'center' } : undefined,
      _vm.team ? { text: 'Anmerkungen', value: 'internalComment', sortable: false, align: 'center' } : undefined,
      !_vm.overview ? { text: 'Halle', value: 'venue', sortable: false, align: 'center' } : undefined,
      _vm.admin ? { text: 'Bearbeiten', value: 'edit', sortable: false, align: 'center' } : undefined,
      _vm.admin ? { text: 'Löschen', value: 'delete', sortable: false, align: 'center' } : undefined,
      _vm.admin || _vm.team ? { text: 'Live-Eingabe', value: 'input', sortable: false, align: 'center' } : undefined,
      _vm.admin || _vm.team ? { text: 'Live-Anzeige', value: 'view', sortable: false, align: 'center' } : undefined,
      _vm.admin ? { text: 'Zuschauer', value: 'spectators', sortable: false, align: 'center' } : undefined,
      _vm.admin ? { text: 'Dokumente', value: 'attachments', sortable: false, align: 'center' } : undefined
    ].filter(function (h) { return !!h; }),"search":_vm.filter,"mobile-breakpoint":0,"items-per-page":50,"item-class":function (item) { return ({'stbm2021bold': item.status === 'EventMovedOnline', 'stbm2021red': item.status === 'EventStarted'}); }},on:{"click:row":_vm.row},scopedSlots:_vm._u([{key:"item.matchday",fn:function(ref){
    var item = ref.item;
return [(item.matchday)?[_vm._v(" "+_vm._s(item.matchday.name)),_c('br'),_vm._v(" "+_vm._s(_vm._f("dateformat")(item.matchday.startDate,'DD.MM.YYYY'))+" ")]:_vm._e()]}},{key:"item.startDate",fn:function(ref){
    var item = ref.item;
return [(item.status === 'EventPostponed')?_c('i',[_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(_vm._f("dateformat")(item.startDate,'DD.MM.YYYY')))]),_c('br'),_vm._v("wird verschoben,"),_c('br'),_vm._v("Termin noch offen")]):_c('span',{domProps:{"innerHTML":_vm._f("dateformat")(item.startDate,'dddd<br>DD.MM.YYYY<br>HH:mm')}})]}},{key:"item.mannschaften",fn:function(ref){
    var item = ref.item;
return [(_vm.admin)?_c('div',{staticStyle:{"min-height":"30px"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('openteams', item._id)}}},[_c('span',{staticStyle:{"text-transform":"none","white-space":"nowrap"},domProps:{"innerHTML":_vm._f("nl2br")(item.mannschaften.join('\n'))}})]):_c('span',{staticStyle:{"text-transform":"none","white-space":"nowrap"},domProps:{"innerHTML":_vm._f("nl2br")(item.mannschaften.join('\n'))}})]}},{key:"item.neutralwaiver",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"none"},domProps:{"innerHTML":_vm._f("nl2br")(item.nv.map(function (n) { return n ? '√' : '-'; }).join('\n'))}})]}},{key:"item.punkte",fn:function(ref){
    var item = ref.item;
return [(item.mode === 'wk_score4')?_vm._l((item.ergebnisse),function(e,i){return _c('div',{key:((item._id) + "-" + i)},[(e)?_c('b',[_vm._v(_vm._s(e.score))]):_vm._e()])}):_vm._l((item.ergebnisse),function(e,i){return _c('div',{key:((item._id) + "-" + i)},[(e)?_c('b',[_vm._v(_vm._s(_vm._f("float2")(e.final)))]):_vm._e()])})]}},{key:"item.gp",fn:function(ref){
    var item = ref.item;
return _vm._l((item.ergebnisse),function(e,i){return _c('div',{key:((item._id) + "-" + i)},[(e)?[_vm._v(_vm._s(e.gp))]:_vm._e()],2)})}},{key:"item.tp",fn:function(ref){
    var item = ref.item;
return _vm._l((item.ergebnisse),function(e,i){return _c('div',{key:((item._id) + "-" + i)},[(e)?[_vm._v(_vm._s(e.tp))]:_vm._e()],2)})}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('base-edit-dialog-select',{attrs:{"value":item.status,"items":_vm.status,"id":item._id},on:{"input":function (ref) {
        var value = ref.value;
        var id = ref.id;

        return _vm.$emit('changestatus', {id: id, value: value});
    }}})]}},{key:"item.werten",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("far fa-bolt")])],1)]}}],null,true)},[_c('v-list',_vm._l((item.teams),function(t){return _c('v-list-item',{key:t.team._id,on:{"click":function($event){$event.stopPropagation();return _vm.$emit('set12', {id: item._id, tid: t.team._id})}}},[_c('v-list-item-title',[_vm._v("12:0 für "),_c('b',[_vm._v(_vm._s(t.team.name))])])],1)}),1)],1)]}},{key:"item.publicComment",fn:function(ref){
    var item = ref.item;
return [(item.publicComment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""}},on),[_c('v-icon',[_vm._v(" far fa-info-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.publicComment)+" ")])]):_vm._e()]}},{key:"item.internalComment",fn:function(ref){
    var item = ref.item;
return [(item.internalComment)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""}},on),[_c('v-icon',[_vm._v(" far fa-info-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.internalComment)+" ")])]):_vm._e()]}},{key:"item.venue",fn:function(ref){
    var item = ref.item;
return [(item.venue)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openmaps(item.venue.address)}}},on),[_c('v-icon',[_vm._v(" far fa-circle-h ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.venue.name)),_c('br'),_vm._v(" "+_vm._s(item.venue.address.streetAddress)+" "+_vm._s(item.venue.address.streetNumber)),_c('br'),_vm._v(" "+_vm._s(item.venue.address.postalCode)+" "+_vm._s(item.venue.address.addressLocality)+" ")])]):_vm._e()]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('edit', item._id)}}},[_c('v-icon',[_vm._v(" far fa-pencil ")])],1)]}},{key:"item.delete",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.del(item._id)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}},{key:"item.input",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function (ev) { return _vm.$emit('input', item._id, ev); }).apply(null, arguments)}}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{staticClass:"far fa-play fa-stack-2x",staticStyle:{"margin-left":"0px","margin-top":"-6px","font-size":"300%"}}),_c('i',{staticClass:"far fa-pencil-alt fa-stack-1x",staticStyle:{"margin-left":"4px","margin-top":"1px","font-size":"100%","text-shadow":"0 0 2px white"}})])])]}},{key:"item.view",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function (ev) { return _vm.$emit('view', item._id, ev); }).apply(null, arguments)}}},[_c('span',{staticClass:"fa-stack fa-1x"},[_c('i',{staticClass:"far fa-play fa-stack-2x",staticStyle:{"margin-left":"0px","margin-top":"-5px","font-size":"300%"}}),_c('i',{staticClass:"far fa-eye fa-stack-1x",staticStyle:{"margin-left":"4px","font-size":"100%","text-shadow":"0 0 2px white"}})])])]}},{key:"item.spectators",fn:function(ref){
    var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":(item.info || {}).zuschauer,"id":item._id},on:{"input":function (ref) {
        var value = ref.value;
        var id = ref.id;

        return _vm.$emit('changespectators', {id: id, value: value});
    }}})]}},{key:"item.attachments",fn:function(ref){
    var item = ref.item;
return [(item.info && item.info.attachments && item.info.attachments.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.info.attachments.length)+" ")])]}}],null,true)},[_c('v-list',_vm._l((item.info.attachments),function(a,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('a',{attrs:{"href":("" + _vm.imageBase + (a._id)),"download":a.filename,"target":"_blank"}},[_vm._v(_vm._s(a.filename))]),_vm._v(" ("+_vm._s(a.mimetype)+") ")])],1)}),1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }